import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"

import LocationMap from "./locationMap"

const LocationMapHolder = ({ plainMap }) => (
  <section className={` map-location ${ !plainMap ? 'map-location--with-info' : '' }`}>
    {!plainMap && (
      <div className="map-location__content-holder">
        <Fade delay={150} direction={"left"} triggerOnce>
          <div className="map-location__content">
            <h2 className="mb-15 content__title">Lokalizacja</h2>
            <p className="subtitle mb-20 content__text">
              ul. Kazimierza Wielkiego 51
              <br />
              Kraków
            </p>
            <Link to="/lokalizacja/" className="btn content__btn">
              Lokalizacja
            </Link>
          </div>
        </Fade>
      </div>
    )}
    <LocationMap />
  </section>
)

export default LocationMapHolder
